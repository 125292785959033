import { useState } from "react";

import { ErasureRequestDetail } from "./ErasureRequestDetail";
import { formatTime } from "../tools/time";
import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useApi } from "../hooks/api";
import { Plan } from "../hooks/types";
import { useQuery } from "@tanstack/react-query";

export const ErasureRequestsList = () => {
  const [selected, setSelected] = useState<Plan | null>(null);
  const { listEraseRequests } = useApi();

  let { data, status } = useQuery(["requests"], listEraseRequests, {
    select: (data) => {
      return data.sort((a: Plan, b: Plan) =>
        a.status.created < b.status.created ? 1 : -1
      );
    },
  });

  if (status === "loading") {
    return <CircularProgress />;
  }

  if (status === "error") {
    return <Alert severity="error">Error loading data from api</Alert>;
  }

  if (data == null) {
    return <Alert severity="warning">No data</Alert>;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell align="right">Name</TableCell>
                  <TableCell align="right">Received</TableCell>
                  <TableCell align="right">&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item: Plan) => (
                  <TableRow key={item.id}>
                    <TableCell component="th" scope="row">
                      {item.subject.email}
                    </TableCell>
                    <TableCell align="right">{item.subject.name}</TableCell>
                    <TableCell align="right">
                      {formatTime(item.status.created)}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        size="small"
                        variant="text"
                        onClick={() => {
                          setSelected(item);
                        }}
                      >
                        Details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={6}>
          {selected && <ErasureRequestDetail id={selected.id} />}
        </Grid>
      </Grid>
    </>
  );
};
