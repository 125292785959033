import { useAuth0 } from "@auth0/auth0-react";
import { TopBar } from "./TopBar";
import { EraseForm } from "./EraseForm";
import { ErasureRequestsList } from "./ErasureRequestsList";

import {
  Container,
  Grid,
  Typography,
  Button,
  LinearProgress,
  Alert,
  AlertTitle,
} from "@mui/material";

import LockIcon from "@mui/icons-material/Lock";

export const App = () => {
  let { loginWithRedirect, isAuthenticated, isLoading, error } = useAuth0();

  if (isLoading) {
    return (
      <>
        <TopBar />
        <LinearProgress />
      </>
    );
  }

  if (error != null) {
    return (
      <>
        <TopBar />
        <Container style={{ marginTop: "30px" }}>
          <Grid>
            <Alert variant={"filled"} severity="error">
              <AlertTitle>{error.name}</AlertTitle>
              {error.message}
            </Alert>
          </Grid>
        </Container>
      </>
    );
  }

  return (
    <>
      <TopBar />
      <>
        <Container maxWidth="xl" style={{ marginTop: "30px" }}>
          {isAuthenticated ? (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <EraseForm />
              </Grid>
              <Grid item xs={12}>
                <ErasureRequestsList />
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <LockIcon />
              <Typography variant="h4">Not Logged In</Typography>
              <Typography variant="subtitle1">Please login first.</Typography>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => loginWithRedirect()}
              >
                Login
              </Button>
            </Grid>
          )}
        </Container>
      </>
    </>
  );
};
