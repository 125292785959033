import { useAuth0 } from "@auth0/auth0-react";
import { useMemo } from "react";
import { Plan } from "./types";

interface API {
  listEraseRequests(): Promise<Plan[]>;
  createEraseRequest(
    email: string,
    name: string,
    notify: boolean
  ): Promise<Plan>;
  getPlan(id: string): Promise<Plan>;
  approvePlan(id: string): Promise<Plan>;
}

export const useApi = (): API => {
  const { getAccessTokenSilently } = useAuth0();

  return useMemo(() => {
    return {
      listEraseRequests: async () => {
        return fetchFromBackend(
          "/erasure-requests/",
          "GET",
          await getAccessTokenSilently()
        ).then((response) => {
          if (response.status === 200) {
            return response.json();
          }
          Promise.reject({
            status: response.status,
            message: response.statusText,
          });
        });
      },
      createEraseRequest: async (email, name, notify) => {
        return fetchFromBackend(
          "/erasure-requests/",
          "POST",
          await getAccessTokenSilently(),
          {
            email: email,
            name: name,
            notify: notify,
          }
        ).then((response) => {
          if (response.status === 200) {
            return response.json();
          }
          Promise.reject({
            status: response.status,
            message: response.statusText,
          });
        });
      },
      getPlan: async (id) => {
        return fetchFromBackend(
          "/erasure-requests/" + id + "/plan",
          "GET",
          await getAccessTokenSilently()
        ).then((response) => {
          if (response.status === 200) {
            return response.json();
          }
          Promise.reject({
            status: response.status,
            message: response.statusText,
          });
        });
      },
      approvePlan: async (id) => {
        return fetchFromBackend(
          "/erasure-requests/" + id + "/plan/approve",
          "POST",
          await getAccessTokenSilently()
        ).then((response) => {
          if (response.status === 200) {
            return response.json();
          }
          Promise.reject({
            status: response.status,
            message: response.statusText,
          });
        });
      },
    };
  }, [getAccessTokenSilently]);
};

const getBaseURL = () => {
  if (window.location.hostname.includes("volumental.com")) {
    return "https://privacy.volumental.com";
  } else if (window.location.hostname.includes("volumental.dev")) {
    return "https://privacy.volumental.dev";
  }
  return "http://localhost:5555";
};

const fetchFromBackend = (
  path: string,
  method: string,
  token: string,
  data?: any
) => {
  const url = getBaseURL() + path;

  let body: string | null = null;
  if (data !== null) {
    body = JSON.stringify(data);
  }

  return fetch(url, {
    method: method,
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: body,
  });
};
