import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./components/App";
import { Auth0Provider } from "@auth0/auth0-react";
import { CssBaseline } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <CssBaseline />
    <Auth0Provider
      domain="volumental.eu.auth0.com"
      redirectUri={window.location.origin}
      clientId="EIVszuIou05Pbp8zYYZ66T1I5Np7aFSk" // client ID for Auth0 application: Volumental Internal Admin UIs
      audience="https://internal.volumental.com"
      responseType="token id_token"
      scope="openid"
    >
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Auth0Provider>
  </React.StrictMode>
);
