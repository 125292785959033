import React, { useCallback, useState } from "react";

import { Plan, Resource } from "../hooks/types";
import { formatTime } from "../tools/time";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  keyframes,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Check,
  HourglassEmpty,
  Info,
  Refresh,
  Sync,
  Error,
} from "@mui/icons-material";
import { useApi } from "../hooks/api";
import { useMutation, useQuery } from "@tanstack/react-query";

import styled from "@emotion/styled";

const RotateFrames = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

const Rotate = styled.div`
  animation: ${RotateFrames} 1s linear infinite;
`;

interface Props {
  id: string;
}

export const ErasureRequestDetail = ({ id }: Props) => {
  const { getPlan, approvePlan } = useApi();
  const [activeState, setActiveState] = useState(0);

  const { status, data, refetch } = useQuery(
    ["requests", id],
    () => getPlan(id),
    {
      enabled: !!id,
      refetchInterval: 5000,
      onSuccess: (plan: Plan) => {
        console.log(plan.status);
        let state = 0;
        if (plan.status.discovery_ended != null) {
          state = 1;
        }
        if (plan.status.approved != null) {
          state = 2;
        }
        if (plan.status.execution_ended != null) {
          state = 3;
        }
        setActiveState(state);
      },
    }
  );

  const mutation = useMutation(
    () => {
      return approvePlan(id);
    },
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const resources = useCallback(() => {
    if (data == null || data.resources == null) {
      return new Map();
    }
    return data.resources.reduce(
      (map: any, e: any) =>
        map.set(e.description.system, [
          ...(map.get(e.description.system) || []),
          e,
        ]),
      new Map()
    );
  }, [data]);

  if (status === "loading") {
    return (
      <Card style={{ position: "sticky", top: "8px" }}>
        <LinearProgress />
        <Box sx={{ padding: 1 }}>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </Box>
      </Card>
    );
  }

  return (
    <React.Fragment>
      <Card style={{ position: "sticky", top: "8px" }}>
        <CardHeader
          action={
            <IconButton onClick={(e) => refetch({})}>
              <Refresh />
            </IconButton>
          }
          title={data?.subject.email}
          subheader={data?.subject.name}
        />
        {data?.status?.connectors?.some((status) => status.failure) && (
          <Alert severity="error">
            <AlertTitle>Connector Error</AlertTitle>
            {data?.status?.connectors
              ?.filter((status) => status.failure)
              .map((status) => (
                <p>
                  Connector <strong>{status.connector}</strong> failed
                  <Tooltip title={status.message}>
                    <IconButton size="small">
                      <Info />
                    </IconButton>
                  </Tooltip>
                </p>
              ))}
          </Alert>
        )}

        {data != null && (
          <div>
            <Stepper activeStep={activeState} alternativeLabel>
              <Step>
                <StepLabel>Discovery</StepLabel>
              </Step>
              <Step>
                <StepLabel>Approval</StepLabel>
              </Step>
              <Step>
                <StepLabel>Execution</StepLabel>
              </Step>
            </Stepper>

            <List dense={true}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <Check />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={formatTime(data?.status.created)}
                  secondary="Created"
                />
              </ListItem>
              {data?.status.discovery_started &&
                data?.status.discovery_ended == null && (
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <Rotate>
                          <Sync />
                        </Rotate>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={formatTime(data?.status.discovery_started)}
                      secondary="Discovery started"
                    />
                  </ListItem>
                )}
              {data?.status.discovery_ended && (
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <Check />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={formatTime(data?.status.discovery_ended)}
                    secondary="Discovery done"
                  />
                </ListItem>
              )}
              {data?.status.approved && (
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <Check />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${formatTime(data?.status.approved)} by ${
                      data?.status.approved_by
                    }`}
                    secondary="Approved"
                  />
                </ListItem>
              )}
              {data?.status.execution_started &&
                data?.status.execution_ended == null && (
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <Rotate>
                          <Sync />
                        </Rotate>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={formatTime(data?.status.execution_started)}
                      secondary="Execution started"
                    />
                  </ListItem>
                )}
              {data?.status.execution_ended && (
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <Check />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={formatTime(data?.status.execution_ended)}
                    secondary="Execution done"
                  />
                </ListItem>
              )}
            </List>
            <CardContent>
              {data?.status.discovery_ended != null &&
                resources().size === 0 && (
                  <Typography>No resources was found.</Typography>
                )}
              {[...resources()].map((item) => (
                <Accordion key={item[0].toString()}>
                  <AccordionSummary id="panel1a-header">
                    <Typography>
                      {item[0]} - {item[1].length} resources
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List dense={true}>
                      {item[1].map((resource: Resource) => (
                        <ListItem key={`${resource.id}`}>
                          <ResourceStatusIcon
                            completed={
                              resource.status.executed != null &&
                              resource.status.failure == null
                            }
                            failure={resource.status.failure != null}
                          ></ResourceStatusIcon>
                          <ListItemText
                            primary={`${resource.description.type}`}
                            secondary={resource.description.identifier}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </AccordionDetails>
                </Accordion>
              ))}
            </CardContent>
            {data?.status.discovery_ended != null &&
              data?.status.approved == null && (
                <CardActions>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      mutation.mutate();
                    }}
                  >
                    Approve plan
                  </Button>
                </CardActions>
              )}
          </div>
        )}
      </Card>
    </React.Fragment>
  );
};

interface ResourceStatusIconProps {
  failure: boolean;
  completed: boolean;
}

const ResourceStatusIcon = ({
  failure,
  completed,
}: ResourceStatusIconProps) => {
  let component = <HourglassEmpty fontSize="small" />;

  if (failure) {
    component = <Error fontSize="small" style={{ color: "red" }} />;
  } else if (completed) {
    component = <Check fontSize="small" />;
  }
  return <ListItemIcon>{component}</ListItemIcon>;
};
