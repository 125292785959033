import React, { useCallback, useState } from "react";

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import { useApi } from "../hooks/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const EraseForm = () => {
  const [email, updateEmail] = useState("");
  const [name, updateName] = useState("");
  const [ticket, setTicket] = useState(false);

  const { createEraseRequest } = useApi();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    () => {
      return createEraseRequest(email, name, ticket);
    },
    {
      onSuccess: () => {
        updateEmail("");
        updateName("");
        setTicket(false);
        queryClient.invalidateQueries(["requests"]);
      },
    }
  );

  const handleEmailChange = (email: string) => {
    updateEmail(email);
  };

  const handleNameChange = (name: string) => {
    updateName(name);
  };

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      mutation.mutate();
    },
    [mutation]
  );

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
      Please input email address for which to erase data
      <Box component={"form"} onSubmit={handleSubmit} sx={{ pt: 1 }}>
        <TextField
          sx={{ mr: 1, mb: 1, ml: 0 }}
          label="Email"
          value={email}
          onChange={(e) => handleEmailChange(e.target.value)}
          size="small"
          variant="outlined"
        />
        <TextField
          sx={{ mr: 1, mb: 1 }}
          value={name}
          label="Name"
          onChange={(e) => handleNameChange(e.target.value)}
          size="small"
          variant="outlined"
        />
        <Button variant="contained" color="primary" name="submit" type="submit">
          Create erasure plan
        </Button>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                value={ticket}
                onChange={(e) => setTicket(e.target.checked)}
              />
            }
            label="Create helpdesk ticket"
          />
        </FormGroup>
      </Box>
    </Box>
  );
};
